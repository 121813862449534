import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { Optional } from '@whoop/web-components/dist/types';
import { FlatProductNode } from '@whoop/web-components/dist/types/Products';

const allProducts = graphql`
  query {
    allShopifyProduct {
      nodes {
        id
        title
        handle
        productType
        descriptionHtml
        createdAt
        shopifyId
        tags
        priceRangeV2 {
          minVariantPrice {
            currencyCode
            amount
          }
          maxVariantPrice {
            currencyCode
            amount
          }
        }
        options {
          id
          name
          values
        }
        variants {
          id
          title
          price
          compareAtPrice
          availableForSale
          inventoryQuantity
          shopifyId
          sku
          image {
            id
            originalSrc
          }
          metafields {
            value
            key
          }
          selectedOptions {
            name
            value
          }
          product {
            handle
            shopifyId
            title
          }
        }
        images {
          id
          originalSrc
        }
      }
    }
    allWhoopProduct {
      nodes {
        colors {
          handle
          label
        }
        description
        featured_media {
          id
          type
          url
        }
        handle
        id
        items {
          employee_discount
          gen3
          gen4
          join_flow
          media {
            url
            type
            id
          }
          member_only
          klaviyo_event
          email_when_oos
          pro_exclusive
          pro_reward
          pro_exclude_discount
          product_highlights
          size {
            handle
            label
          }
          sku
          storefront
          title
          color {
            handle
            label
          }
          new
        }
        message
        multi_product_label
        sizes {
          handle
          label
        }
        title
        media {
          id
          type
          url
        }
        category_label
        children_handles
        parent_handle
        product_highlights
        womens
        varying_pattern
        unisex
        storefront
        sizing_guide
        root_handle
        product_pages
        join_flow
        gen4
        gen3
        active
      }
    }
  }
`;

function getFullWhoopShopifyProduct(
  productServiceResult: Map<FlatProductNode>,
  shopifyResult: Map<ShopifyProduct>,
): FullProduct[] {
  const formattedProducts: FullProduct[] = [];
  Object.keys(productServiceResult).forEach((key) => {
    const shopifyProduct: ShopifyProduct = shopifyResult[key];
    if (shopifyProduct) {
      const formattedProduct: FullProduct = {
        metafields: shopifyProduct.metafields,
        tags: shopifyProduct.tags,
        shopifyId: shopifyProduct.shopifyId,
        priceRangeV2: shopifyProduct.priceRangeV2,
        variants: shopifyProduct.variants,
        ...productServiceResult[key],
      };
      formattedProducts.push(formattedProduct);
    }
  });
  return formattedProducts;
}

function getFilteredFullWhoopShopifyProduct(
  productServiceResult: Map<FlatProductNode>,
  shopifyResult: Map<ShopifyProduct>,
  filter: string,
): FullProduct[] {
  const formattedProducts: FullProduct[] = [];
  Object.keys(shopifyResult)
    .filter((key) => {
      const _shopifyProduct: ShopifyProduct = shopifyResult[key];
      return (
        !_shopifyProduct.tags.includes('__hidden') &&
        _shopifyProduct.tags.includes(filter)
      );
    })
    .forEach((key) => {
      const shopifyProduct: ShopifyProduct = shopifyResult[key];
      if (shopifyProduct) {
        const formattedProduct: FullProduct = {
          metafields: shopifyProduct.metafields,
          shopifyId: shopifyProduct.shopifyId,
          tags: shopifyProduct.tags,
          priceRangeV2: shopifyProduct.priceRangeV2,
          variants: shopifyProduct.variants,
          ...productServiceResult[key],
        };
        formattedProducts.push(formattedProduct);
      }
    });
  return formattedProducts;
}

function getFullWhoopShopifyProductByHandle(
  productServiceResult: Map<FlatProductNode>,
  shopifyResult: Map<ShopifyProduct>,
  handles: string[],
) {
  const formattedProducts: FullProduct[] = [];
  handles.forEach((handle: string, index: number) => {
    const shopifyProduct: ShopifyProduct = shopifyResult[handle];
    if (shopifyProduct) {
      const formattedProduct: FullProduct = {
        metafields: shopifyProduct.metafields,
        shopifyId: shopifyProduct.shopifyId,
        tags: shopifyProduct.tags,
        priceRangeV2: shopifyProduct.priceRangeV2,
        variants: shopifyProduct.variants,
        orderize: index, // placing the order number that the products come in (they come in order of manual(featured)) -> filtering needs this
        createdAt: shopifyProduct.createdAt, // filtering needs this value as well
        ...productServiceResult[handle],
      };
      formattedProducts.push(formattedProduct);
    }
  });
  return formattedProducts;
}

export function useAllProducts(filter?: string | string[], byHandle?: boolean) {
  const allProductsFormated = useStaticQuery<{
    allWhoopProduct: {
      nodes: FlatProductNode[];
    };
    allShopifyProduct: {
      edges: {
        node: {
          id: string;
          handle: string;
        };
      };
      nodes: ShopifyProduct[];
    };
  }>(allProducts);

  return useMemo(() => {
    // use cases
    // get all the products in format { ...FullProduct } -> for search
    // get all products filtered on tags in format { ...FullProduct } -> for homepage
    // get FullProduct by handle -> used in collections

    if (!allProductsFormated) {
      return;
    }
    const allWhoopProduct: Map<FlatProductNode> = {};
    allProductsFormated.allWhoopProduct.nodes.forEach((props) => {
      allWhoopProduct[props.handle] = { ...props };
    });
    const allShopifyProduct: Map<ShopifyProduct> = {};
    allProductsFormated.allShopifyProduct.nodes.forEach((props) => {
      allShopifyProduct[props.handle] = { ...props };
    });
    if (filter && !byHandle) {
      return getFilteredFullWhoopShopifyProduct(
        allWhoopProduct,
        allShopifyProduct,
        filter as string,
      );
    } else if (filter && byHandle) {
      return getFullWhoopShopifyProductByHandle(
        allWhoopProduct,
        allShopifyProduct,
        filter as string[],
      );
    } else {
      return getFullWhoopShopifyProduct(allWhoopProduct, allShopifyProduct);
    }
  }, [allProductsFormated]);
}

interface ShopifyProduct {
  availableForSale: boolean;
  description: string;
  descriptionHtml: string;
  handle: string;
  id: string;
  metafields: ShopifyMetafield[];
  tags: string[];
  title: string;
  shopifyId: string;
  productType: string;
  priceRangeV2: PriceRangeV2;
  onlineStoreUrl: string;
  variants: ShopifyVariant[];
  createdAt: string;
}

export type Map<T> = { [id: string]: T };

export interface FullProduct extends FlatProductNode {
  variants: ShopifyVariant[];
  tags: string[];
  metafields: ShopifyMetafield[];
  priceRangeV2: PriceRangeV2;
  orderize?: number;
  createdAt?: string;
  shopifyId: string;
}

export interface ShopifyVariant {
  quantityAvailable: number;
  availableForSale: boolean;
  compareAtPrice: Optional<string>;
  id: string;
  image: { id: string; originalSrc: string }[];
  metafields: { key: string; value: string }[];
  price: string;
  product: { handle: string; shopifyId: string; title: string };
  selectedOptions: { name: string; value: string }[];
  shopifyId: string;
  sku: string;
  title: string;
}

interface PriceRangeV2 {
  minVariantPrice: Price;
  maxVariantPrice: Price;
}

interface Price {
  currencyCode: string;
  amount: number;
}

export interface ShopifyMetafield {
  key: string;
  value: string;
}
