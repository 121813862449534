import { graphql, useStaticQuery } from 'gatsby';
import {
  ShopifyProductVariant,
  ShopifyProductVariantConnection,
} from '../types/generated';
import { useMemo } from 'react';

const ShopifyVariantsByIdQuery = graphql`
  query ShopifyVariantsById {
    allShopifyProductVariant(filter: { sku: { ne: "" } }) {
      nodes {
        sku
        title
        price
        compareAtPrice
        inventoryQuantity
        shopifyId
        product {
          shopifyId
          handle
          title
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

export interface ShopifyVariantsBySku {
  [sku: string]: ShopifyProductVariant;
}

function useShopifyVariantBySku(): ShopifyVariantsBySku {
  const queryResult = useStaticQuery<{
    allShopifyProductVariant: ShopifyProductVariantConnection;
  }>(ShopifyVariantsByIdQuery);
  return useMemo<ShopifyVariantsBySku>(() => {
    const variantSkuMap: ShopifyVariantsBySku = {};
    queryResult?.allShopifyProductVariant?.nodes?.forEach((variant) => {
      if (variant?.sku) {
        if (variantSkuMap[variant.sku]) {
          if (
            (variant.inventoryQuantity || 0) >
            (variantSkuMap[variant.sku].inventoryQuantity || 0)
          ) {
            variantSkuMap[variant.sku] = variant;
          }
        } else {
          variantSkuMap[variant.sku] = variant;
        }
      }
    });
    return variantSkuMap;
  }, [queryResult]);
}

export default useShopifyVariantBySku;
